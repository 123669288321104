import React, { useRef } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import CardGeneral from './card-general'
import { Check } from 'react-feather'

import { useHiddenmsClassCity } from '../hooks/useHiddenmsClass'

const SidebarHeader = styled.h4`
  margin-bottom: 0.5em;
`
const SidebarP = styled.p`
  color: #444;
`
const TextPhoneStyle = styled.span``

const ContactInfo = () => {
  const footerRefs = useHiddenmsClassCity({
    handler: (ref) => {
      const className = ref.current.classList
      ref.current.className = className + ' hiddenms'
    },
  })
  const addressRef = useRef()
  const addressHeadingRef = useRef()
  footerRefs.push(addressRef)
  footerRefs.push(addressHeadingRef)
  return (
    <StaticQuery
      query={graphql`
        query ContactInfoQuery {
          site {
            siteMetadata {
              companyInfo {
                phone 
                email
                addressLine1
                addressLine2
                city
                state
                zip
                license
              }
            }
          }
        }
      `}
      render={(data) => {
        let { siteMetadata } = data.site
        return (
          <>
            <CardGeneral title={'About Us'}>
              {siteMetadata.companyInfo.license ? (
                <div>
                  <SidebarHeader>Licensed and Insured</SidebarHeader>
                  <SidebarP>
                    License #: {siteMetadata.companyInfo.license}
                  </SidebarP>
                </div>
              ) : null}

              <SidebarHeader>Contact Us</SidebarHeader>

              <SidebarP>
                <b>Office:</b>{' '}
                <TextPhoneStyle>
                  {siteMetadata.companyInfo.phone}
                </TextPhoneStyle>
              </SidebarP>
            
              <SidebarP ref={addressRef}>
                {siteMetadata.companyInfo.addressLine1}{' '}
                {siteMetadata.companyInfo.addressLine2}
                {siteMetadata.companyInfo.addressLine1 ? ',' : null}
                {siteMetadata.companyInfo.addressLine1 ? <br /> : null}
                {siteMetadata.companyInfo.city}, {siteMetadata.companyInfo.state}{' '}
                {siteMetadata.companyInfo.zip}
              </SidebarP>
            </CardGeneral>
          </>
        )
      }}
    />
  )
}
export default ContactInfo
