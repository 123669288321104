import React from 'react'
import styled from 'styled-components'

const ReputationContainer = styled.div`
  margin: -5px;
`

const ReviewsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const ReviewItem = styled.div`
  padding: 0.65em 0.75em;
  flex: ${(props) => (props.largeScreen ? '0 0 16.66667%' : '0 0 33%')};
  display: flex;
  flex-direction: column;
  -webkit-box-pack: end;
  justify-content: flex-end;
  img {
    margin-bottom: 5px;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding: 0;
  }
`
const ReviewItemDetails = styled.div`
  h4,
  p {
    margin: 0;
    text-align: center;
  }
  h4 {
    margin-top: 0.35em;
    font-size: 1em;
    font-weight: 600 !important;
  }
  p {
    font-size: 0.65em;
    line-height: 1.1;
    padding-top: 0.25em;
  }
`

const sources = [
  {
    alt: 'Yelp 5 Stars',
    url: '/assets/logos/yelp.png',
    rating: '5/5',
  },
  {
    alt: 'Google 5 Stars',
    url: '/assets/logos/google.png',
    rating: '5/5',
  },
  
  {
    alt: 'Facebook 5 Stars',
    url: '/assets/logos/fb.png',
    rating: '5/5',
  },
  {
    alt: 'Angies List 5 Stars',
    url: '/assets/logos/angies.png',
    rating: '5/5',
  },
  {
    alt: 'Porch 5 Stars',
    url: '/assets/logos/porch.png',
    rating: '5/5',
  },
  {
    alt: 'Houzz 5 Stars',
    url: '/assets/logos/houzz.png',
    rating: '5/5',
  },
  {
    alt: 'GuildQuality 4.9 Stars',
    url: '/assets/logos/guildquality.png',
    rating: '4.9/5',
  },
  {
    alt: 'BuildZoom 5 Stars',
    url: '/assets/logos/buildzoom.png',
    rating: '5/5',
  },
]

export default class Reputation extends React.Component {
  render() {
    return (
      <ReputationContainer
        className={
          this.props && this.props.largeScreen
            ? 'reputation-bar-full-width'
            : null
        }
      >
        <ReviewsGrid>
          {sources.map(({ alt, url, rating }, i) => (
            <ReviewItem key={i}>
              <img
                src={url}
                alt={alt}
                width="auto"
                height="auto"
                loading="lazy"
              />
              <ReviewItemDetails>
                <h4>{rating}</h4>
              </ReviewItemDetails>
            </ReviewItem>
          ))}
        </ReviewsGrid>
      </ReputationContainer>
    )
  }
}
